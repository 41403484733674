ng-multiselect-dropdown[ng-reflect-model]:not([ng-reflect-model='']) .dropdown-btn {
    padding-bottom: 1px !important;
}

.multiselect-dropdown {
    .multiselect-item-checkbox input[type='checkbox'] + div:before {
        border-color: $maestro !important;
        color: $maestro !important;
        border-radius: 3px;
    }
    .multiselect-item-checkbox input[type='checkbox']:checked + div:before {
        background-color: $maestro !important;
    }

    .dropdown-btn {
        max-height: 200px;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;

        .selected-item {
            background: $maestro !important;
            border: 0 !important;
            margin-bottom: 5px;

            a {
                color: transparent !important;
            }
            a:after {
                content: '×';
                font-weight: bold;
                color: white;
                margin-left: -5px;
            }
        }

        .selected-item:hover {
            box-shadow: none !important;
            background: $maestro-darkened !important;
        }

        .dropdown-down,
        .dropdown-up {
            border: 0 !important;
            width: 10px !important;
            height: 12px !important;
            background-image: url('data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzczODE4ZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+') !important;
        }

        .dropdown-down {
            transform: rotate(-90deg) !important;
        }

        .dropdown-up {
            transform: rotate(90deg) !important;
        }
    }

    input[type='checkbox'] + div:before {
        border-color: #20a8d8 !important;
        color: #20a8d8 !important;
        border-radius: 3px;
    }

    input[type='checkbox']:checked + div:before {
        background: #20a8d8 !important;
    }
}

.dropdown-in-tab{
    .dropdown-list{
        position: relative !important;
    }
}
